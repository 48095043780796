/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../components/commonInput";
import MiinedButton from "../../components/Button";
import { addCoupon } from "../../store/adminDashboard/actions";
import "./index.scss";

const AddCouponsModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payload, setPayload] = useState({
    coupon_name: "",
    limit: "",
    duration: "",
    is_active: false,
  });
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.adminDashboard);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  const handleDone = () => {
    dispatch(addCoupon(payload));
    setTimeout(() => {
      handleCancel();
    }, 2000);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="addOtheres_model"
      width={500}
    >
      <div className="add_otheres_modal">
        <div style={{ fontWeight: 600, fontSize: "18px" }}>Add Coupon</div>
        <div
          className="content"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <CommonInput
            placeholder="Enter Coupon Name"
            name="coupon_name"
            type="text"
            value={payload.coupon_name}
            handleChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <CommonInput
            placeholder="Enter Coupon Usage Limit"
            name="limit"
            type="number"
            value={payload.limit}
            handleChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <CommonInput
            placeholder="Enter Coupon duration"
            name="duration"
            type="number"
            value={payload.duration}
            handleChange={handleChange}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <div>
            <input
              id="is_active"
              type="checkbox"
              checked={payload.is_active}
              name="is_active"
              onChange={(e) => {
                setPayload({ ...payload, [e.target.name]: e.target.checked });
              }}
              style={{ marginRight: "5px" }}
            />
            <label style={{ marginBottom: "5px" }} htmlFor="is_active">
              Set Coupon as Active.
            </label>
          </div>
        </div>
        <div className="submit_btnContainer">
          <MiinedButton
            title={loader ? "Adding..!" : "Add"}
            onClick={() => handleDone()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddCouponsModal;
