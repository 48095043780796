/* eslint-disable */
import * as types from "./types";

const INITIAL_STATE = {
  loader: false,
  users: {},
  usersPageCount: 0,
  couponsPageCount: 0,
  couponsUseresPageCount: 0,
  coupons: {},
  couponUsers: {},
  adminList: [],
  usersCount: null,
  currentTab: "1",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        users: {
          ...state.users,
          [action.payload.currentPage]: action.payload?.result_list,
        },
        usersPageCount: action.payload?.page_count,
      };
    case types.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case types.RESET_USER_LIST:
      return {
        ...state,
        loader: false,
        users: {},
        usersPageCount: 0,
      };

    case types.UPDATE_USERS_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        // users: action.payload,
      };
    case types.UPDATE_USERS_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.ADD_USERS_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.ADD_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        // users: action.payload,
      };
    case types.ADD_USERS_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.COUPONS_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.COUPONS_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        coupons: {
          ...state.coupons,
          [action.payload.currentPage]: action.payload?.result_list,
        },
        couponsPageCount: action.payload?.page_count,
      };
    case types.COUPONS_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case types.RESET_COUPONS_LIST:
      return {
        ...state,
        loader: false,
        coupons: {},
        couponsPageCount: 0,
      };

    case types.ADD_COUPON_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.ADD_COUPON_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.ADD_COUPON_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.UPDATE_COUPON_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.UPDATE_COUPON_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.ADMIN_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.ADMIN_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        adminList: action.payload,
      };
    case types.ADMIN_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.GET_COUPONS_USERS_REQUEST:
      return {
        ...state,
        couponUsersLoader: true,
      };
    case types.GET_COUPONS_USERS_SUCCESS:
      return {
        ...state,
        couponUsersLoader: false,
        couponUsers: {
          ...state.couponUsers,
          [action.payload.currentPage]: action.payload?.result_list,
        },
        couponsUseresPageCount: action.payload?.page_count,
      };
    case types.GET_COUPONS_USERS_FAILURE:
      return {
        ...state,
        couponUsersLoader: false,
      };
    case types.RESET_COUPONS_USERES_LIST:
      return {
        ...state,
        loader: false,
        couponUsers: {},
        couponsUseresPageCount: 0,
      };

    case types.UPDATE_ISACTIVE_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.UPDATE_ISACTIVE_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.UPDATE_ISACTIVE_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.GET_USERS_COUNTS_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.GET_USERS_COUNTS_SUCCESS:
      return {
        ...state,
        loader: false,
        usersCount: action.payload,
      };
    case types.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };

    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
