import { Select, Pagination, Spin, Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../components/commonInput";
import { useState, useEffect } from "react";
import AddUserModal from "./addUserModal";
import UpdateUserModal from "./updateUserModal";
import moment from "moment";
import UserCharts from "./userCharts";
import {
  getUsersCount,
  getUsersList,
  resetUserList,
} from "../../store/adminDashboard/actions";

const { Column } = Table;

const Users = () => {
  const dispatch = useDispatch();
  let { loader, users, usersPageCount, usersCount } = useSelector(
    (state) => state.adminDashboard
  );
  const [currentPage, setCurrentPage] = useState(1);
  users = users[currentPage];
  const [searchValue, setSearchValue] = useState("");
  const [seletcedTierID, setSeletcedTierID] = useState(null);

  const [localUsersState, setLocalUsersState] = useState("");
  const [AddUserModalVisible, setAddUserModalVisible] = useState(false);
  const [UpdateUserModalVisible, setUpdateUserModalVisible] = useState(false);
  const [currentUserObject, setcurrentUserObject] = useState(null);

  const handleChange = (e) => {
    // if (e !== null)
    //   setLocalUsersState(users.filter((item) => item.tier_id === e));
    // else setLocalUsersState(users);
    setSeletcedTierID(e);
    dispatch(resetUserList());
    dispatch(getUsersList(formQuery(1, e, searchValue)));
    setCurrentPage(1);
  };

  const formQuery = (page, tierId, filter) => {
    let queryParams = `page=${page}`;
    if (tierId && tierId !== null) {
      queryParams = queryParams + `&tier_id=${tierId}`;
    }
    if (filter && filter?.trim()) {
      queryParams =
        queryParams + `&name=${filter?.trim()}&email=${filter?.trim()}`;
    }
    return queryParams;
  };

  useEffect(() => {
    setLocalUsersState(users);
  }, [users]);

  useEffect(() => {
    dispatch(getUsersCount());
  }, []);

  useEffect(() => {
    dispatch(getUsersList(formQuery(currentPage)));
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(resetUserList());
      dispatch(getUsersList(formQuery(1, seletcedTierID, searchValue)));
      setCurrentPage(1);
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <div>
      <Spin size="large" spinning={loader}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              background: "white",
            }}
          >
            <h2>
              Users{" "}
              {/* <span
                style={{
                  background: "rgba(67, 137, 250, 0.1)",
                  color: "#1890ff",
                  padding: "0px 10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddUserModalVisible(!AddUserModalVisible);
                }}
              >
                +
              </span> */}
            </h2>
            <div
              style={{ display: "flex", alignItems: "center", width: "60%" }}
            >
              <div style={{ width: "20%" }}>Filter By Tier ID : </div>
              <Select
                defaultValue={null}
                value={seletcedTierID}
                style={{ width: "33%", marginRight: "20px" }}
                onChange={handleChange}
                options={[
                  { value: null, label: "Select Tier Id" },
                  { value: 0, label: "Tier Id 0" },
                  { value: 1, label: "Tier Id 1" },
                  { value: 2, label: "Tier Id 2" },
                  { value: 3, label: "Tier Id 3" },
                  { value: 4, label: "Tier Id 4" },
                  { value: 5, label: "Tier Id 5" },
                ]}
              />
              <div style={{ width: "33%" }}>
                <CommonInput
                  placeholder="Search by email or name..."
                  name="email"
                  type="text"
                  value={searchValue}
                  handleChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div>
            <Table dataSource={localUsersState} pagination={false}>
              <Column
                title="First Name"
                dataIndex="first_name"
                key="first_name"
                // sorter={(a, b) => a.first_name.localeCompare(b.first_name)}
              />
              <Column
                title="Last Name"
                dataIndex="last_name"
                key="last_name"
                // sorter={(a, b) => a.last_name.localeCompare(b.last_name)}
              />
              <Column
                title="Email"
                dataIndex="email"
                key="email"
                // sorter={(a, b) => a.email.localeCompare(b.email)}
              />
              <Column
                title="Tier ID(Subscription ID)"
                dataIndex="tier_id"
                key="tier_id"
                // sorter={(a, b) => a.tier_id - b.tier_id}
              />
              <Column
                title="Last login"
                dataIndex="last_login"
                key="last_login"
                render={(_, record) => (
                  <div>
                    {record.last_login
                      ? moment(record.last_login).format("MM/DD/YYYY")
                      : "N/A"}
                  </div>
                )}
                // sorter={(a, b) =>
                //   new Date(a.last_login) - new Date(b.last_login)
                // }
              />
              {/* <Column
                title="Action"
                key="action"
                render={(_, record) => (
                  <Space
                    size="middle"
                    onClick={() => {
                      setUpdateUserModalVisible(!UpdateUserModalVisible);
                      setcurrentUserObject(record);
                    }}
                  >
                    <a>Update</a>
                  </Space>
                )}
              /> */}
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "15px",
              }}
            >
              <Pagination
                pageSize={10}
                defaultCurrent={currentPage}
                total={usersPageCount * 10}
                onChange={(e) => {
                  dispatch(
                    getUsersList(formQuery(e, seletcedTierID, searchValue))
                  );
                  setCurrentPage(e);
                }}
                pageSizeOptions={[]}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
        <UserCharts users={usersCount} />
        <AddUserModal visible={AddUserModalVisible} />
        <UpdateUserModal
          visible={UpdateUserModalVisible}
          currentUserObject={currentUserObject}
        />
      </Spin>
    </div>
  );
};

export default Users;
