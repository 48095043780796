import React from "react";
import { Table, Spin, Switch, Pagination, Select } from "antd";
import CommonInput from "../../components/commonInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCouponsList,
  resetCouponsList,
  updateCoupon,
} from "../../store/adminDashboard/actions";
import AddCouponsModal from "./addCouponModal";
import UpdateCouponsModal from "./updateCouponModal";
import histroy from "../../routes/History";

const CouponsComponent = (props) => {
  const dispatch = useDispatch();
  let { loader, coupons, couponsPageCount } = useSelector(
    (state) => state.adminDashboard
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isActive, setIsActive] = useState(null);
  coupons = coupons[currentPage];
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [currentCouponObject, setcurrentCouponObject] = useState(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "coupon_name",
      key: "coupon_name",
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
    },
    {
      title: "Usage Count",
      dataIndex: "usage_count",
      key: "usage_count",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (value, record) => {
        return (
          <a onClick={(e) => e.stopPropagation()}>
            <Switch
              defaultChecked
              // onClick={(e) => e.stopPropagation()}
              onChange={(e) => onChangeIsActive(e, record)}
              checked={value}
            />
          </a>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Action",
      key: "operation",
      render: (_, record) => (
        <div>
          <a
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              setshowUpdateModal(!showUpdateModal);
              setcurrentCouponObject(record);
            }}
          >
            Edit
          </a>
          {/* <a>Delete</a> */}
        </div>
      ),
    },
  ];

  const onChangeIsActive = (checked, couponObj) => {
    const obj = {
      coupon_name: couponObj.coupon_name,
      limit: Number(couponObj.limit),
      duration: couponObj.duration,
      is_active: checked,
      coupon_id: couponObj.coupon_id,
    };
    dispatch(updateCoupon(obj));
  };

  const appendKey = (data) => {
    if (Array.isArray(data))
      return data?.map((item, i) => {
        return { ...item, key: i.toString() };
      });
  };

  const formQuery = (page, isActive, filter) => {
    let queryParams = `page=${page}`;
    if (isActive !== null) {
      queryParams = queryParams + `&filter=${isActive}`;
    }
    if (filter && filter?.trim()) {
      queryParams = queryParams + `&coupon_name=${filter?.trim()}`;
    }
    return queryParams;
  };

  useEffect(() => {
    dispatch(getCouponsList(formQuery(currentPage, isActive, searchValue)));
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(resetCouponsList());
      dispatch(getCouponsList(formQuery(1, isActive, searchValue)));
      setCurrentPage(1);
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <Spin size="large" spinning={loader}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            background: "white",
          }}
        >
          <h2>
            Coupons{" "}
            <span
              style={{
                background: "rgba(67, 137, 250, 0.1)",
                color: "#1890ff",
                padding: "0px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowModal(!showModal);
              }}
            >
              +
            </span>
          </h2>
          <Select
            defaultValue={null}
            value={isActive}
            style={{ width: "33%", marginRight: "20px" }}
            onChange={(e) => {
              dispatch(resetCouponsList());
              setIsActive(e);
              dispatch(getCouponsList(formQuery(1, e, searchValue)));
            }}
            options={[
              { value: null, label: "Filter By Coupon Status" },
              { value: "active", label: "Active" },
              { value: "inactive", label: "In Active" },
            ]}
          />
          <div style={{ width: "30%" }}>
            <CommonInput
              placeholder="Search by name"
              name="coupon"
              type="text"
              value={searchValue}
              handleChange={(e) => {
                setSearchValue(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={appendKey(coupons)}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  event.preventDefault();
                  histroy.push({ pathname: "/couponUser", state: record });
                }, // click row
              };
            }}
            pagination={false}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "15px",
            }}
          >
            <Pagination
              pageSize={10}
              defaultCurrent={currentPage}
              total={couponsPageCount * 10}
              onChange={(e) => {
                dispatch(getCouponsList(formQuery(e, isActive, searchValue)));
                setCurrentPage(e);
              }}
              pageSizeOptions={[]}
              showSizeChanger={false}
            />
          </div>
        </div>
        <AddCouponsModal visible={showModal} />
        <UpdateCouponsModal
          visible={showUpdateModal}
          currentCouponObject={currentCouponObject}
        />
      </div>
    </Spin>
  );
};

export default CouponsComponent;
