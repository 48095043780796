export const GET_USERS_LIST_REQUEST = "adminDashboard/GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "adminDashboard/GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "adminDashboard/GET_USERS_LIST_FAILURE";
export const RESET_USER_LIST = "adminDashboard/RESET_USER_LIST";

export const UPDATE_USERS_REQUEST = "adminDashboard/UPDATE_USERS_REQUEST";
export const UPDATE_USERS_SUCCESS = "adminDashboard/UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAILURE = "adminDashboard/UPDATE_USERS_FAILURE";

export const ADD_USERS_REQUEST = "adminDashboard/ADD_USERS_REQUEST";
export const ADD_USERS_SUCCESS = "adminDashboard/ADD_USERS_SUCCESS";
export const ADD_USERS_FAILURE = "adminDashboard/ADD_USERS_FAILURE";

export const COUPONS_LIST_REQUEST = "adminDashboard/COUPONS_LIST_REQUEST";
export const COUPONS_LIST_SUCCESS = "adminDashboard/COUPONS_LIST_SUCCESS";
export const COUPONS_LIST_FAILURE = "adminDashboard/COUPONS_LIST_FAILURE";
export const RESET_COUPONS_LIST = "adminDashboard/RESET_COUPONS_LIST";

export const ADD_COUPON_REQUEST = "adminDashboard/ADD_COUPON_REQUEST";
export const ADD_COUPON_SUCCESS = "adminDashboard/ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAILURE = "adminDashboard/ADD_COUPON_FAILURE";

export const UPDATE_COUPON_REQUEST = "adminDashboard/UPDATE_COUPON_REQUEST";
export const UPDATE_COUPON_SUCCESS = "adminDashboard/UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILURE = "adminDashboard/UPDATE_COUPON_FAILURE";

export const ADD_ADMIN_USER_REQUEST = "adminDashboard/ADD_ADMIN_USER_REQUEST";
export const ADD_ADMIN_USER_SUCCESS = "adminDashboard/ADD_ADMIN_USER_SUCCESS";
export const ADD_ADMIN_USER_FAILURE = "adminDashboard/ADD_ADMIN_USER_FAILURE";

export const GET_COUPONS_USERS_REQUEST =
  "adminDashboard/GET_COUPONS_USERS_REQUEST";
export const GET_COUPONS_USERS_SUCCESS =
  "adminDashboard/GET_COUPONS_USERS_SUCCESS";
export const GET_COUPONS_USERS_FAILURE =
  "adminDashboard/GET_COUPONS_USERS_FAILURE";
export const RESET_COUPONS_USERES_LIST =
  "adminDashboard/RESET_COUPONS_USERES_LIST";

export const ADMIN_LIST_REQUEST = "adminDashboard/ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "adminDashboard/ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAILURE = "adminDashboard/ADMIN_LIST_FAILURE";

export const UPDATE_ISACTIVE_REQUEST = "adminDashboard/UPDATE_ISACTIVE_REQUEST";
export const UPDATE_ISACTIVE_SUCCESS = "adminDashboard/UPDATE_ISACTIVE_SUCCESS";
export const UPDATE_ISACTIVE_FAILURE = "adminDashboard/UPDATE_ISACTIVE_FAILURE";

export const GET_USERS_COUNTS_REQUEST =
  "adminDashboard/GET_USERS_COUNTS_REQUEST";
export const GET_USERS_COUNTS_SUCCESS =
  "adminDashboard/GET_USERS_COUNTS_SUCCESS";
export const GET_USERS_COUNTS_FAILURE =
  "adminDashboard/GET_USERS_COUNTS_FAILURE";

export const RESET = "adminDashboard/RESET";
export const SET_CURRENT_TAB = "adminDashboard/SET_CURRENT_TAB";
