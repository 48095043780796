import { Pagination, Select, Spin, Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UnnlockLayout from "../layout";
import CommonInput from "../../components/commonInput";
import { useState, useEffect } from "react";
import {
  getCouponsUsers,
  resetCouponUsersList,
  setCurrentTab,
} from "../../store/adminDashboard/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MiinedButton from "../../components/Button";
import histroy from "../../routes/History";

const { Column } = Table;

const CouponUsers = () => {
  const locationSate = useLocation();
  const dispatch = useDispatch();
  let { couponUsersLoader, couponUsers, couponsUseresPageCount } = useSelector(
    (state) => state.adminDashboard
  );
  const [currentPage, setCurrentPage] = useState(1);
  couponUsers = couponUsers[currentPage];
  const [searchValue, setSearchValue] = useState("");
  const [localUsersState, setLocalUsersState] = useState("");
  const [seletcedTierID, setSeletcedTierID] = useState(null);

  const handleChange = (e) => {
    // if (e !== null)
    //   setLocalUsersState(couponUsers.filter((item) => item.tier_id === e));
    // else setLocalUsersState(couponUsers);
    setSeletcedTierID(e);
    dispatch(resetCouponUsersList());
    dispatch(getCouponsUsers(formQuery(1, e, searchValue)));
    setCurrentPage(1);
  };

  const formQuery = (page, tierId, filter) => {
    let queryParams = `page=${page}`;
    if (tierId && tierId !== null) {
      queryParams = queryParams + `&tier_id=${tierId}`;
    }
    if (filter && filter?.trim()) {
      queryParams =
        queryParams + `&name=${filter?.trim()}&email=${filter?.trim()}`;
    }
    return `${queryParams}&coupon=${locationSate.state.coupon_name}`;
  };

  useEffect(() => {
    setLocalUsersState(couponUsers);
  }, [couponUsers]);

  useEffect(() => {
    if (locationSate && locationSate.state && locationSate.state.coupon_name)
      dispatch(getCouponsUsers(formQuery(currentPage)));
  }, [locationSate.state.coupon_name]);

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(resetCouponUsersList());
      dispatch(getCouponsUsers(formQuery(1, seletcedTierID, searchValue)));
      setCurrentPage(1);
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <UnnlockLayout>
      <div style={{ padding: "5%" }}>
        <div style={{ marginBottom: "10px" }}>
          <MiinedButton
            title={"< Coupons"}
            onClick={() => {
              histroy.goBack();
              dispatch(setCurrentTab("2"));
            }}
          />
        </div>
        <Spin size="large" spinning={couponUsersLoader}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                background: "white",
              }}
            >
              <h2>Users Of {locationSate.state.coupon_name}</h2>
              <div
                style={{ display: "flex", alignItems: "center", width: "60%" }}
              >
                <div style={{ width: "20%" }}>Filter By Tier ID : </div>
                <Select
                  defaultValue={null}
                  value={seletcedTierID}
                  style={{ width: "33%", marginRight: "20px" }}
                  onChange={handleChange}
                  options={[
                    { value: null, label: "Select Tier Id" },
                    { value: 0, label: "Tier Id 0" },
                    { value: 1, label: "Tier Id 1" },
                    { value: 2, label: "Tier Id 2" },
                    { value: 3, label: "Tier Id 3" },
                    { value: 4, label: "Tier Id 4" },
                    { value: 5, label: "Tier Id 5" },
                  ]}
                />
                <div style={{ width: "33%" }}>
                  <CommonInput
                    placeholder="Search by email..."
                    name="email"
                    type="text"
                    value={searchValue}
                    handleChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Table dataSource={localUsersState} pagination={false}>
                {/* <Column
                  title="First Name"
                  dataIndex="first_name"
                  key="first_name"
                />
                <Column
                  title="Last Name"
                  dataIndex="last_name"
                  key="last_name"
                /> */}
                <Column title="Email" dataIndex="email" key="email" />
                <Column
                  title="Tier ID(Subscription ID)"
                  dataIndex="tier_id"
                  key="tier_id"
                />
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: "15px",
                }}
              >
                <Pagination
                  pageSize={10}
                  defaultCurrent={currentPage}
                  total={couponsUseresPageCount * 10}
                  onChange={(e) => {
                    dispatch(
                      getCouponsUsers(formQuery(e, seletcedTierID, searchValue))
                    );
                    setCurrentPage(e);
                  }}
                  pageSizeOptions={[]}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </UnnlockLayout>
  );
};

export default CouponUsers;
