import * as types from "./types";

export const getUsersList = (payload) => ({
  type: types.GET_USERS_LIST_REQUEST,
  payload,
});
export const getUsersListSuccess = (payload) => ({
  type: types.GET_USERS_LIST_SUCCESS,
  payload,
});
export const getUsersListFailure = (payload) => ({
  type: types.GET_USERS_LIST_FAILURE,
  payload,
});
export const resetUserList = (payload) => ({
  type: types.RESET_USER_LIST,
  payload,
});

export const updateUser = (payload) => ({
  type: types.UPDATE_USERS_REQUEST,
  payload,
});
export const updateUserSuccess = (payload) => ({
  type: types.UPDATE_USERS_SUCCESS,
  payload,
});
export const updateUserFailure = (payload) => ({
  type: types.UPDATE_USERS_FAILURE,
  payload,
});

export const addUser = (payload) => ({
  type: types.ADD_USERS_REQUEST,
  payload,
});
export const addUserSuccess = (payload) => ({
  type: types.ADD_USERS_SUCCESS,
  payload,
});
export const addUserFailure = (payload) => ({
  type: types.ADD_USERS_FAILURE,
  payload,
});

export const getCouponsList = (payload) => ({
  type: types.COUPONS_LIST_REQUEST,
  payload,
});
export const getCouponsListSuccess = (payload) => ({
  type: types.COUPONS_LIST_SUCCESS,
  payload,
});
export const getCouponsListFailure = (payload) => ({
  type: types.COUPONS_LIST_FAILURE,
  payload,
});
export const resetCouponsList = (payload) => ({
  type: types.RESET_COUPONS_LIST,
  payload,
});

export const addCoupon = (payload) => ({
  type: types.ADD_COUPON_REQUEST,
  payload,
});
export const addCouponSuccess = (payload) => ({
  type: types.ADD_COUPON_SUCCESS,
  payload,
});
export const addCouponFailure = (payload) => ({
  type: types.ADD_COUPON_FAILURE,
  payload,
});

export const updateCoupon = (payload) => ({
  type: types.UPDATE_COUPON_REQUEST,
  payload,
});
export const updateCouponSuccess = (payload) => ({
  type: types.UPDATE_COUPON_SUCCESS,
  payload,
});
export const updateCouponFailure = (payload) => ({
  type: types.UPDATE_COUPON_FAILURE,
  payload,
});

export const addAdminUser = (payload) => ({
  type: types.ADD_ADMIN_USER_REQUEST,
  payload,
});
export const addAdminUserSuccess = (payload) => ({
  type: types.ADD_ADMIN_USER_SUCCESS,
  payload,
});
export const addAdminUserFailure = (payload) => ({
  type: types.ADD_ADMIN_USER_FAILURE,
  payload,
});

export const getCouponsUsers = (payload) => ({
  type: types.GET_COUPONS_USERS_REQUEST,
  payload,
});
export const getCouponsUsersSuccess = (payload) => ({
  type: types.GET_COUPONS_USERS_SUCCESS,
  payload,
});
export const getCouponsUsersFailure = (payload) => ({
  type: types.GET_COUPONS_USERS_FAILURE,
  payload,
});
export const resetCouponUsersList = (payload) => ({
  type: types.RESET_COUPONS_USERES_LIST,
  payload,
});

export const getAdminList = (payload) => ({
  type: types.ADMIN_LIST_REQUEST,
  payload,
});
export const adminListSuccess = (payload) => ({
  type: types.ADMIN_LIST_SUCCESS,
  payload,
});
export const adminListFailure = (payload) => ({
  type: types.ADMIN_LIST_FAILURE,
  payload,
});

export const updateIsActive = (payload) => ({
  type: types.UPDATE_ISACTIVE_REQUEST,
  payload,
});
export const updateIsActiveSuccess = (payload) => ({
  type: types.UPDATE_ISACTIVE_SUCCESS,
  payload,
});
export const updateIsActiveFailure = (payload) => ({
  type: types.UPDATE_ISACTIVE_FAILURE,
  payload,
});

export const getUsersCount = (payload) => ({
  type: types.GET_USERS_COUNTS_REQUEST,
  payload,
});
export const getUsersCountSuccess = (payload) => ({
  type: types.GET_USERS_COUNTS_SUCCESS,
  payload,
});
export const getUsersCountFailure = (payload) => ({
  type: types.GET_USERS_COUNTS_FAILURE,
  payload,
});

export const setCurrentTab = (payload) => ({
  type: types.SET_CURRENT_TAB,
  payload,
});
