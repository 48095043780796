// Login Types
export const LOGIN_REQUEST = "login/LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "login/LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "login/LOGIN_REQUEST_FAILURE";
export const LOGIN_REQUEST_NEWADMIN = "login/LOGIN_REQUEST_NEWADMIN";

export const LOGIN_RESPOND_REQUEST = "login/LOGIN_RESPOND_REQUEST";
export const LOGIN_RESPOND_REQUEST_SUCCESS = "login/LOGIN_RESPOND_REQUEST_SUCCESS";
export const LOGIN_RESPOND_REQUEST_FAILURE = "login/LOGIN_RESPOND_REQUEST_FAILURE";

// GetUser Types
export const GET_USER_DETAILS_REQUEST = "login/GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_REQUEST_SUCCESS =
  "login/GET_USER_DETAILS_REQUEST_SUCCESS";
export const GET_USER_DETAILS_REQUEST_FAILURE =
  "login/GET_USER_DETAILS_REQUEST_FAILURE";

  // GetUser Types
export const EMAIL_VERIFICATION_REQUEST = "login/EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_REQUEST_SUCCESS =
  "login/EMAIL_VERIFICATION_REQUEST_SUCCESS";
export const EMAIL_VERIFICATION_REQUEST_FAILURE =
  "login/EMAIL_VERIFICATION_REQUEST_FAILURE";

// Forgot Password Types
export const FORGOT_PASSWORD_REQUEST = "login/FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "login/FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "login/FORGOT_PASSWORD_FAILURE";

// Reset Password Types
export const RESET_PASSWORD_REQUEST = "login/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "login/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "login/RESET_PASSWORD_FAILURE";

// Refresh LogIn Token Request Types
export const REFRESH_LOGIN_REQUEST = "login/REFRESH_LOGIN_REQUEST";
export const REFRESH_LOGIN_SUCCESS = "login/REFRESH_LOGIN_SUCCESS";
export const REFRESH_LOGIN_FAILURE = "login/REFRESH_LOGIN_FAILURE";

// Refresh LogIn Token Request Types
export const GET_SUBSCRIPTION_COUNT_REQUEST =
  "login/GET_SUBSCRIPTION_COUNT_REQUEST";
export const GET_SUBSCRIPTION_COUNT_SUCCESS =
  "login/GET_SUBSCRIPTION_COUNT_SUCCESS";
export const GET_SUBSCRIPTION_COUNT_FAILURE =
  "login/GET_SUBSCRIPTION_COUNT_FAILURE";

export const APPLY_COUPON_REQUEST = "componies/APPLY_COUPON_REQUEST";
export const APPLY_COUPON_SUCCESS = "componies/APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAILURE = "componies/APPLY_COUPON_FAILURE";

export const SET_SUBSCRIPTION_LOADER = "login/SET_SUBSCRIPTION_LOADER";

// check tokan expiry
export const CHECK_TOKAN_EXPIRY = "login/CHECK_TOKAN_EXPIRY";

// Reset
export const RESET = "login/RESET";
