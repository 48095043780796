import React from "react";
import UnnlockLayout from "../layout";
import { Tabs } from "antd";
import CouponsComponent from "./couponsComponent";
import AdminUsers from "./adminUsers";
import Users from "./users";
import { setCurrentTab } from "../../store/adminDashboard/actions";
import { useDispatch, useSelector } from "react-redux";

const AdminDashboard = (props) => {
  const dispatch = useDispatch();
  let { currentTab } = useSelector((state) => state.adminDashboard);

  const onChange = (key) => {
    dispatch(setCurrentTab(key));
  };

  const items = [
    {
      key: "1",
      label: "Users",
      children: <Users />,
    },
    {
      key: "2",
      label: "Coupons",
      children: <CouponsComponent />,
    },
    {
      key: "3",
      label: "Admin Users",
      children: <AdminUsers />,
    },
  ];

  console.log(currentTab, "currentTab");

  return (
    <UnnlockLayout>
      <div style={{ padding: "5%" }}>
        <Tabs defaultActiveKey={currentTab} items={items} onChange={onChange} />
      </div>
    </UnnlockLayout>
  );
};

export default AdminDashboard;
