import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import _ from "lodash";

const UserCharts = ({ users }) => {
  const barChartOption = {
    title: {
      text: "Subscriptions Stats",
      subtext: "Based on tier id's",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    xAxis: {
      type: "category",
      data: ["Subscriptions"],
    },
    yAxis: {
      type: "value",
    },
    grid: [
      {
        left: "20%",
      },
    ],
    series: users?.map((item) => {
      return {
        name: item?.tier_name,
        type: "bar",
        // stack: "total",
        label: {
          show: true,
        },
        data: [item?.user_count],
        barWidth: "100",
      };
    }),
  };

  return (
    <div
      style={{
        background: "white",
        padding: "10px",
        marginTop: "20px",
        borderRadius: "10px",
      }}
    >
      {/* <ReactECharts option={pieChartPption} /> */}
      <ReactECharts option={barChartOption} />
    </div>
  );
};

export default UserCharts;
