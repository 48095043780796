/*eslint-disable*/
import React from "react";
import {
  Router,
  Switch,
  Redirect,
  Route,
  // useHistory,
} from "react-router-dom";
import history from "./History";
import NewRegistration from "../containers/NewRegistration";
import NewProfile from "../containers/Profile/NewProfile";
import ResetPassword from "../containers/ResetPassword/ResetPasswordContainer";
import ForgotPasswordContainer from "../containers/ForgotPassword/ForgotPasswordContainer";
import PrivateRoute from "./privateRoute";
import AdminDashboard from "../containers/AdminDashboard";
import CouponUsers from "../containers/AdminDashboard/couponUsers";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <NewRegistration />
        </Route>
        <Route exact path="/couponUser">
          <CouponUsers />
        </Route>
        <Route exact path="/registration">
          <NewRegistration />
        </Route>
        <Route exact path="/login">
          <NewRegistration />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPasswordContainer />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword />
        </Route>
        <PrivateRoute component={NewProfile} path="/profile" exact />
        <PrivateRoute component={AdminDashboard} path="/adminDashboard" exact />
        <Redirect from="**" to="/" exact />
      </Switch>
    </Router>
  );
};

export default Routes;
